import React, { useState } from "react";
import Hero from "./Hero";
import hero_bg from "../assets/img/hero_bg.jpg";
import Section from "./Section";
import ImageTextSection from "./ImageTextSection";
import Image_1 from "../assets/img/Whistleblowing_cosa_fare.jpg";
import Image_2 from "../assets/img/Whistleblowing_shadow.jpg"
import Image_3 from "../assets/img/whistleblowing_come_funziona.jpg"
import Image_4 from "../assets/img/whistleblowing_in_regola.jpg"


export default function Body(props) {
  const [formStatus, setFormStatus] = useState(undefined);

  return (
    <div className="overflow-x-hidden">
      <Hero
        title="SafeReport - Whistleblowing"
        content="Piattaforma sicura per il whistleblowing per le pubbliche amministrazioni e aziende private con più di 50 dipendenti"
        image={hero_bg}
      />

      <Section>
        <div className="container mx-auto py-8">
          <ImageTextSection
            reverse
            image={Image_1}
            title="Cosa si può fare?"
            content={
              <>
                <p className="mb-3 text-gray-500 dark:text-gray-400">Gestisci illimitate segnalazioni attraverso un canale informatico criptato e sicuro che garantisce la completa tutela del segnalante!</p>
                <p className="mb-3 text-gray-500 dark:text-gray-400">Il <b>segnalante</b> o <b>whistleblower</b> può:</p>
                <ul className="list-disc list-inside mb-3">
                  <li className="">inserire le proprie segnalazioni tramite un'interfaccia semplice e intuitiva</li>
                  <li className="">accedere in anonimato al sistema, conservando il codice unico della segnalazione</li>
                  <li className="">comunicare con il Responsabile Whistleblowing sempre in modo del tutto anonimo</li>
                  <li className="">integrare le segnalazioni effettuate</li>
                </ul>
                <p className="mb-3 text-gray-500 dark:text-gray-400">Il <b>Responsabile whistleblowing</b>/membro dell’Organismo di vigilanza può:</p>
                <ul className="list-disc list-inside">
                  <li className="">accedere ad un'area di amministrazione per prendere visione e gestire le segnalazioni ricevute</li>
                  <li className="">interagire con il segnalante, preservandone l'identità</li>
                  <li className="">richiedere ulteriori informazioni o documenti</li>
                  <li className="">monitorare e gestire la procedura in tutte le sue fasi</li>
                </ul>
              </>
            }
          />
        </div>
      </Section>

      <Section primary>
        <div className="container mx-auto py-8">
          <ImageTextSection
            image={Image_2}
            title="Perché SafeReport?"
            content={
            <>
              <p className="mb-3 text-gray-500 dark:text-gray-400">Il <b>Decreto n. 24/2023</b> impone alle <b>Pubbliche Amministrazioni</b> e <b>Aziende Private</b> che abbiano certi requisiti di dotarsi di uno strumento di Whistleblowing, ovvero un canale che permetta la segnalazione di illeciti e violazioni di disposizioni normative nazionali o europee.</p>
              <p className="mb-3 text-gray-500 dark:text-gray-400">SafeReport è la risposta chiavi in mano a questa esigenza: è una piattaforma online, intuitiva e semplice da usare, sulla quale è possibile segnalare eventuali illeciti nel massimo rispetto della riservatezza, così come previsto dalla normativa.</p>
              <ol className="list-decimal list-inside">
                <li className="mb-2"><b>Sicurezza al Primo Posto:</b> La tua sicurezza è garantita attraverso la crittografia end-to-end e il protocollo di sicurezza avanzato di SafeReport, assicurando la massima confidenzialità delle segnalazioni, rispettando le specifiche normative del settore pubblico e privato.</li>
                <li className="mb-2"><b>Conformità Normativa:</b> SafeReport rispetta e supera i rigorosi standard di conformità per le Pubbliche Amministrazioni e le Aziende Private, garantendo una gestione etica e conforme delle segnalazioni.</li>
                <li className="mb-2"><b>Anonimato Sicuro:</b> SafeReport utilizza tecnologie avanzate di protezione dell'anonimato specificamente adattate alle esigenze delle organizzazioni pubbliche e private.</li>
              </ol>
            </>
            }
          />
        </div>
      </Section>

      <Section>
        <div className="container mx-auto py-8">
          <ImageTextSection
            reverse
            image={Image_3}
            title="Come Funziona?"
            content={
              <>
                <p className="mb-3 text-gray-500 dark:text-gray-400">Grazie a SafeReport è possibile creare un fascicolo, un file report, crittografato e anonimo, che illustri ciò che si vuole mettere a conoscenza dell'organizzazione che sta adottando la piattaforma.</p>
                <p className="mb-3 text-gray-500 dark:text-gray-400">Alla creazione del report, verranno chieste le informazioni di base, il format è personalizzabile in base alle vostre esigenze specifiche.</p>
                  <ol className="list-decimal list-inside">
                    <li className="mb-2"><b>Segnalazione Crittografata:</b> gli account sono sicuri, anonimi e crittografati per documentare dettagliatamente le segnalazioni, rispettando le specifiche normative del settore.</li>
                    <li className="mb-2"><b>Analisi Settoriale:</b> il nostro team di esperti in whistleblowing eseguirà un'analisi dei requisiti e delle specifiche per il settore pubblico e privato.</li>
                    <li className="mb-2"><b>Protezione Tecnologica del Whistleblower:</b> SafeReport impiega tecnologie avanzate di protezione specifiche per garantire la sicurezza delle segnalazioni nelle Pubbliche Amministrazioni e nelle Aziende Private.</li>
                  </ol>
              </>
            }
          />
        </div>
      </Section>

      <Section primary>
        <div className="container mx-auto py-8">
          <ImageTextSection
            image={Image_4}
            title="Non siete ancora in Regola?"
            content={
              <>
                <p className="mb-3 text-gray-500 dark:text-gray-400">SafeReport è la piattaforma di <b>Whistleblowing per PMI, Pubblica amministrazione e grandi aziende</b> che permette di tutelarsi a norma di legge e gestire facilmente in pochi click le segnalazioni di illeciti.</p>
                <p className="mb-3 text-gray-500 dark:text-gray-400">Uno strumento di <b>compliance aziendale</b> che prevede un insieme di garanzie e tutele rispetto ad eventuali azioni ritorsive nei confronti del segnalante, nonché le misure volte ad evitare l’abuso di questo potere di segnalazione.</p>
                <p className="mb-3 text-gray-500 dark:text-gray-400">Secondo la <b>Direttiva UE 2019/1937 e con l’approvazione del D. Lgs. 24/2023</b>, dovranno adeguarsi alla normativa del Whistleblowing:</p>
                <ul className="list-disc list-inside">
                  <li className="mb-2"><b>entro il 15 luglio 2023</b>, dagli enti pubblici, le aziende e organizzazioni private con più di 249 dipendenti.</li>
                  <li className="mb-2"><b>entro il 17 dicembre 2023</b>, le aziende private che abbiano impiegato, nell’ultimo anno, una media dai 50 fino ai 249 lavoratori subordinati (tempo determinato e indeterminato).</li>
                </ul>
              </>
            }
          />
        </div>
      </Section>

      <Section>
        <div className="container mx-auto py-12 lg:py-14 xl:py-20">
          <div className="prose rich-text-block max-w-full text-right ml-auto mr-0" >
            <h3 className="text-accent text-center">
              SafeReport: All'avanguardia nella whistleblowing per un futuro lavorativo più sicuro ed etico, sia nel pubblico che nel privato.
            </h3>
            <p className="text-center">Consente di regolamentare le procedure atte ad incentivare e proteggere le segnalazioni degli illeciti, in linea con il dettato normativo e linee guida ANAC.</p>
            <p className="text-center">È lo strumento informatico che consente agli operatori del settore Pubblico o Privato di effettuare segnalazioni di illeciti con la garanzia di estrema riservatezza.</p>
          </div>
        </div>
      </Section>
      <Section accent>
        <div className="container mx-auto py-12 lg:py-14 xl:py-20">
          <div className="flex flex-col w-full gap-10 lg:gap-20 lg:flex-row">
            <div className="prose w-full lg:w-1/2 lg:mt-10">
              <h2 className="text-black lg:text-4xl font-medium mb-2">
                Contattaci
              </h2>
              <p className="text-white lg:leading-6">
                Azienda Pubblica o Privata con più di 50 dipendenti? <br />
                Compila il modulo, teniamoci in contatto! </p>
              <p className="text-white lg:leading-6">
                Abbiamo molte soluzioni per te e ti aiuteremo ad essere in regola con la normativa vigente.
              </p>
            </div>
            <div className="w-full lg:w-1/2">
              {formStatus === undefined && (
                <form className="border-gray focus:border-gray" onSubmit={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  const data = new FormData(e.target);
                  data.append('access_key', '66007c54-ea78-45da-9b5f-5e7658f22156')

                  const request = await fetch("https://api.web3forms.com/submit", {
                    method: 'POST',
                    body: data
                  })

                  try {
                    const json = await request.json();
                    setFormStatus(json.success)
                    // Google tag (gtag.js) event
                    gtag('event', 'manual_event_SUBMIT_LEAD_FORM', {
                      // <event_parameters>
                    });
                  } catch (e) {
                    console.error(e);
                    setFormStatus(false)
                  }

                  return false;
                }}>
                  <div className="flex flex-col lg:flex-row gap-4 mb-4">
                    <div className="flex-1">
                      <label className="mb-1 text-label">Nome</label>
                      <input type="text" name="name" className="text-black form-input mt-1 block w-full rounded-md border-gray focus:border-gray" />
                    </div>
                    <div className="flex-1">
                      <label className="mb-1 text-label">E-mail</label>
                      <input type="text" name="email" className="text-black form-input mt-1 block w-full rounded-md" />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="mb-1 text-label">Messaggio</label>
                    <textarea name="message" className="text-black form-textarea mt-1 block w-full rounded-md" placeholder="" rows="5"></textarea>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between items-start gap-4">
                    <div className="text-xs max-w-sm text-gray-dark">This site is protected by reCAPTCHA and the Google<a target="_blank" rel="noopener noreferrer" className="font-bold text-black" href="https://policies.google.com/privacy"> Privacy Policy</a> and<a target="_blank" rel="noopener noreferrer" className="font-bold text-black" href="https://policies.google.com/terms"> Terms of Service</a> apply.</div>
                    <button type="submit" className="bg-gray-light hover:bg-gray text-white font-bold py-2 px-4 rounded">
                      Invia
                    </button>
                  </div>
                  <div className="invisible">
                    <div>
                      <div className="grecaptcha-badge" style={{width: '256px', height: '60px', boxShadow: 'gray 0px 0px 5px'}}>
                        <div className="grecaptcha-logo">
                          {/* <iframe title="reCAPTCHA" src="./Home Page_files/anchor.html" width="256" height="60" role="presentation" name="a-6gky8ia55oqt" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe> */}
                        </div>
                        <div className="grecaptcha-error"></div>
                        <textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response" style={{width: '250px', height: '40px', border: '1px solid rgb(193, 193, 193)', margin: '10px 25px', padding: '0px', resize: 'none', display: 'none'}}></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              )}
              {formStatus === true && (
                <div className="h-full flex justify-center flex-col text-center">
                  <p className="font-bold">Grazie per averci contattato</p>
                  <p>Un nostro consulente ti contattera il prima possibile</p>
                </div>
              )}
              {formStatus === false && (
                <div className="h-full flex justify-center flex-col text-center">
                  <p className="font-bold">Errore</p>
                  <p>Si è verificato un errore imprevisto.</p>
                  <p>Contattaci al numero <a href="tel:0957791192">095 7791192</a></p>
                  <div className="mt-4">
                    <a className="btn btn-secondary" onClick={() => setFormStatus(undefined)}>RIPROVA</a>
                  </div>
                </div>
              )}
              <script src="https://web3forms.com/client/script.js" async defer></script>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
