import React from 'react';
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import Timing from 'components/Timing.js';
import Header from 'components/Header.js';
import Section from 'components/Section.js';

export default function Privacy() {
  return (
    <>
      <Header />
      <Section>
        <div className="container mt-3 mx-auto py-12 lg:py-14 xl:py-20">
          <div className="prose rich-text-block max-w-full text-left" >
            <h1>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI RESA AI SENSI DELL'ART. 13 DEL REGOLAMENTO (UE) 2016/679 SUL TRATTAMENTO DEI DATI PERSONALI</h1>
            <p>La presente informativa è rivolta ai soggetti che effettuano la segnalazione di presunti illeciti presso l’Ente ai sensi del D. Lgs. N. 24 del 10 marzo 2023, in attuazione della direttiva (UE) 2019/1937 del Parlamento europeo e del Consiglio, riguardante la protezione delle persone che segnalano violazioni del diritto dell’Unione e recante disposizioni riguardanti la protezione delle persone che segnalano violazioni delle disposizioni normative nazionali.</p>

            <h3>TITOLARE DEL TRATTAMENTO, RESPONSABILE PROTEZIONE DEI DATI E CONTATTI</h3>
            <p>Il titolare del trattamento è l’Ente, il Responsabile della protezione dei dati designato può essere contattato all’indirizzo web istituzionale dell’Ente.</p>

            <h3>FINALITÀ DEL TRATTAMENTO</h3>
            <p>I dati personali forniti vengono trattati dal Responsabile della prevenzione della corruzione e della trasparenza nell'esecuzione dei propri compiti di interesse pubblico o comunque connessi all'esercizio dei propri pubblici poteri, con particolare riferimento al compito di accertare eventuali illeciti denunciati nell’interesse dell’Ente. In particolare, i dati personali comunicati vengono trattati al fine di avviare le opportune attività istruttorie al fine di verificare la fondatezza della segnalazione e le eventuali azioni da intraprendere in caso di fondatezza.</p>

            <h3>BASE GIURIDICA</h3>
            <p>I dati personali sono trattati ai sensi:</p>
            <ul>
              <li>dell’art. 6, co. 1, lett. c) del Reg. UE 2016/679 per adempiere un obbligo legale al quale è soggetto il Titolare del trattamento;</li>
              <li>dell’art. 6, co. 1, lett. f) del Reg. UE 2016/679 nell'esecuzione dei propri compiti di interesse pubblico o comunque connessi all'esercizio dei propri pubblici poteri.</li>
            </ul>

            <h3>TIPOLOGIA DEI DATI TRATTATI</h3>
            <p>Il trattamento ha ad oggetto i dati identificativi comunicati dal soggetto segnalante che possono riguardare lo stesso segnalante ed eventuali altri soggetti che a vario titolo sono interessati dalla vicenda segnalata.</p>

            <h3>MODALITÀ DI TRATTAMENTO E CONSERVAZIONE</h3>
            <p>Il trattamento dei dati viene effettuato attraverso l’utilizzo di una piattaforma informatica accessibile tramite il sito web dell’Ente e disponibile al seguente indirizzo https://safereport.it/.</p>
            <p>Il trattamento avverrà con logiche rispondenti alle finalità sopraindicate e, comunque, in modo da garantire la sicurezza e la riservatezza dei dati anche con sistema di crittografia. In particolare, la trasmissione dei dati forniti dal segnalante mediante compilazione della piattaforma è gestita con protocollo HTTPS.</p>
            <p>La gestione e la conservazione dei dati personali avverrà su server ubicati all’interno dell’Unione Europea gestiti da GL S.r.l.s., con sede in Giarre (CT), Viale Don Minzoni, 46. Al momento della stesura della presente Informativa sul Trattamento Dati i server sono situati in Francia. Resta in ogni caso inteso che, ove si rendesse necessario, si avrà facoltà di spostare l’ubicazione dei server in Italia e/o in altro paese dell’Unione Europea. I dati non saranno oggetto di trasferimento al di fuori dell’Unione Europea.</p>
            <p>Le segnalazioni e la relativa documentazione saranno conservate per il tempo necessario al trattamento della segnalazione e comunque non oltre cinque anni a decorrere dalla data di comunicazione dell’esito finale della procedura di segnalazione, nel rispetto degli obblighi di riservatezza (art. 12 del D. Lgs. N. 24/23).</p>

            <h3>DESTINATARI DEI DATI</h3>
            <p>Ai sensi dell’art. 12 del D. Lgs. N. 24/23 l’identità del segnalante e dei soggetti tutelati non possono essere rivelate senza il consenso espresso del segnalante a persone diverse da quelle competenti a ricevere espressamente autorizzate (facilitatore, persone coinvolta, persona segnalata).</p>
            <p>I dati raccolti a seguito della segnalazione potranno essere comunicati all’Autorità Giudiziaria, la Corte dei conti e l’ANAC solo nei casi espressamente previsti dalla legge.</p>

            <h3>DIRITTI DEGLI INTERESSATI</h3>
            <p>I diritti riconosciuti in materia di protezione dei dati (artt. 15 a 22 del GDPR), non possono essere esercitati qualora dall’esercizio di tali diritti potrebbe derivare un pregiudizio effettivo e concreto alla tutela della riservatezza dell’identità della persona segnalante ai sensi dell’art. 2-undecies del D. Lgs. 196/2003.</p>
          </div>
        </div>
      </Section>
      <Footer />
    </>
    );
  }
