import React from "react";
import Seo from "./Seo";

export default function Hero(props) {
  return (
    <section className="flex-shrink-0 relative flex items-center"
      style={{
        backgroundImage: `url(${props.image})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }}
    >
      <Seo
        title={props.title}
        description={props.content}
        type="webapp"
        author="SPLIT ® - GL S.r.l.s."
      />
      <div className="absolute inset-0" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}></div>
      <div className="prose-xl relative container mx-auto px-6 z-10 py-12 lg:py-32 xl:py-40 text-2xl">
        <div className="max-w-3xl text-left ml-0 mr-auto">
          <h1 className="text-white font-bold text-h1 sm:text-3x1 xl:text-5xl xl:leading-tight mb-4 mt-8 text-left">{props.title}</h1>
          <p className="text-white bg-accent p-10 text-body md:text-body-lg xl:text-2xl">
            {props.content}
          </p>
        </div>
      </div>
    </section>
  );

}
