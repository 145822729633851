import React from 'react';
import Header from 'components/Header.js';
import Section from 'components/Section.js';
import Card from "../components/Card.js";
import Footer from "../components/Footer.js";

import obblighi_img from "../assets/img/obblighi-scadenze-whistleblowing.jpg";
import regolamento_img from "../assets/img/regolamento-e-linee-guida-anac-whistleblowing.jpg";
import chi_puo_segnalare_img from "../assets/img/whistleblowing-chi-puo-segnalare.jpg";

export default function Privacy() {
  return (
    <>
      <Header />
      <Section>
        <div className='container pt-20 mx-auto prose rich-text-block max-w-full text-center'>
          <h1>NORMATIVA WHISTLEBLOWING</h1>
        </div>
      </Section>

      <Section>
        <div className="container grid md:grid-cols-3 mx-auto py-12 lg:py-14">
            <Card
              align="left"
              image= {obblighi_img}
              title="ENTI COINVOLTI"
              content=
              {<>
                <p>I destinatari della nuova disciplina sono sia i soggetti pubblici che privati. I soggetti del settore pubblico sono le amministrazioni pubbliche, le autorità amministrative indipendenti, gli enti pubblici economici, i concessionari di pubblico servizio, le imprese a controllo pubblico e le imprese in house, anche se quotate.</p>
                <p>I soggetti del settore privato sono quelli che:</p>
                <ul>
                  <li>hanno impiegato, nell’ultimo anno, la media di almeno 50 lavoratori subordinati con contratti di lavoro a tempo indeterminato o determinato;</li>
                  <li>rientrano nell’ambito di applicazione degli atti dell’Unione di cui alle parti I.B e II dell’Allegato al Decreto (che ripropone l’Allegato alla Direttiva UE), anche se nell’ultimo anno non hanno raggiunto la media di 50 lavoratori subordinati. Si tratta dei settori dei servizi, prodotti e mercati finanziari, prevenzione del riciclaggio e del finanziamento del terrorismo, nonché della sicurezza dei trasporti;</li>
                  <li>sono diversi dai soggetti di cui al numero b), sono dotati di un modello di organizzazione e gestione 231, anche se nell’ultimo anno non hanno raggiunto la media di 50 lavoratori subordinati.</li>
                </ul>
                <p>Ai fini del computo della media annua dei lavoratori impiegati nel settore privato - necessaria per stabilire quando si supera la soglia dei 50 lavoratori - in linea con un’istanza di Confindustria, ANAC ha specificato che si debba fare riferimento all’ultimo anno solare precedente a quello in corso, salvo per le imprese di nuova costituzione per le quali si considera l’anno in corso (ovvero il 2023).</p>
                <p>Pertanto, per le imprese diverse da quelle di nuova costituzione, in sede di prima applicazione occorrerà fare riferimento alla media annua dei lavoratori impiegati al 31 dicembre 2022 e poi, per le annualità successive, si dovrà considerare il computo dell’anno solare precedente, sempre al 31 dicembre.</p>
                <p>Il riferimento all’ultimo anno solare precedente a quello in cui avviene la segnalazione, per il calcolo della media annua dei lavoratori impiegati è un criterio che, rispondendo alla norma di legge, consente alle imprese di adeguarsi agli eventuali adempimenti con ragionevole tempestività.</p>
                <p>Inoltre, l’ANAC ha precisato che “ai fini del calcolo della media dei lavoratori impiegati negli enti del settore privato deve farsi riferimento al valore medio degli addetti (Elaborazione dati INPS) al 31/12 dell’anno solare precedente a quello in corso, contenuto nelle visure camerali. Quando l’impresa è di nuova costituzione, considerato che il dato in questione viene aggiornato trimestralmente, va preso come riferimento il valore medio calcolato nell’ultima visura.”.</p>
              </>}
            />
            <Card
              align="left"
              image= {regolamento_img}
              title="COSA SI PUò SEGNALARE"
              content=
              {<>
                <p>Il d.lgs. n. 24/2023 stabilisce che possono essere oggetto di segnalazione, divulgazione pubblica o denuncia, le informazioni sulle violazioni, compresi i fondati sospetti, di normative nazionali e dell’Unione europea che ledono l’interesse pubblico o l’integrità dell’amministrazione pubblica o dell’ente privato commesse nell’ambito organizzativo dell’ente con cui il segnalante intrattiene uno di rapporti giuridici e di cui sia venuto a conoscenza.</p>
                <p>A titolo esemplificativo possono costituire oggetto della segnalazione:</p>
                <ul>
                  <li>Violazioni del diritto nazionale;</li>
                  <li>Illeciti amministrativi, contabili, civili o penali;</li>
                  <li>condotte illecite rilevanti ai sensi del decreto legislativo 8 giugno 2001, n. 231;</li>
                  <li>Violazioni del diritto dell’UE;</li>
                  <li>Atti od omissioni che ledono gli interessi finanziari dell'Unione Europea;</li>
                  <li>Atti od omissioni riguardanti il mercato interno, che compromettono la libera circolazione delle merci, delle persone, dei servizi e dei capitali.</li>
                </ul>
                <p>Possono essere, inoltre, oggetto della segnalazione tutti quegli elementi che riguardano condotte volte ad occultare le violazioni stesse.</p>
                <p>Sono escluse dall’ambito oggettivo tutte quelle informazioni che appaiono palesemente prive di fondamento, di dominio pubblico o acquisite attraverso il “vociferare”.</p>
              </>}
            />
            <Card
              align="left"
              image= {chi_puo_segnalare_img}
              title="CHI PUò SEGNALARE"
              content=
              {<>
                <p>Le disposizioni del decreto legislativo 24/2023 si applicano alle persone segnalanti che segnalano, denunciano all'autorità giudiziaria o contabile o divulgano pubblicamente le violazioni di cui sono venute a conoscenza nell'ambito del proprio contesto lavorativo. In particolare, i soggetti che possono segnalare sono:</p>
                <ul>
                  <li>Lavoratori subordinati, ivi compresi i Lavoratori il cui rapporto di lavoro è disciplinato dal d.lgs. n. 81/2015 o Lavoratori che svolgono prestazioni occasionali;</li>
                  <li>Lavoratori autonomi;</li>
                  <li>Lavoratori autonomi che svolgono la propria attività lavorativa presso soggetti del settore privato;</li>
                  <li>Titolari di un rapporto di collaborazione;</li>
                  <li>Titolari di un rapporto di collaborazione di cui all’art. 2 del d.lgs. n. 81/2015. Si tratta delle collaborazioni organizzate dal committente che si concretino in prestazioni di lavoro esclusivamente personali e continuative, le cui modalità di esecuzione siano organizzate dal committente;</li>
                  <li>Liberi professionisti e consulenti che prestano la propria attività presso soggetti del settore privato;</li>
                  <li>Volontari e tirocinanti, retribuiti e non retribuiti;</li>
                  <li>Azionisti persone fisiche, quando presenti;</li>
                  <li>Persone con funzioni di amministrazione, direzione, controllo, vigilanza o rappresentanza.</li>
                </ul>
                <p>Le tutele si applicano anche qualora la segnalazione provenga:</p>
                <ul>
                 <li>da chi non ha un rapporto giuridico non è ancora iniziato;</li>
                  <li>dai Candidati se, le informazioni sulle violazioni, sono state acquisite durante il processo di selezione o in altre fasi precontrattuali;</li>
                  <li>da chi è si trova nel periodo di prova;</li>
                  <li>successivamente allo scioglimento del rapporto giuridico se le informazioni sulle violazioni sono state acquisite nel corso del rapporto stesso. </li>
                </ul>
                <p>Ai sensi dell’art. 3, comma 5, del D.Lgs. 24/2023, la tutela è riconosciuta, oltreché al Segnalante, anche a tutti quei soggetti che, tuttavia, potrebbero essere destinatari di ritorsioni in forza del ruolo assunto nell’ambito del processo di segnalazione. In particolare, le misure di protezione di cui al Decreto n.24/2023 si applicano:</p>
                <ul>
                  <li>Facilitatori;</li>
                  <li>Persone del medesimo contesto lavorativo con legame di parentela fino a quarto grado e legame affettivo stabile;</li>
                  <li>Colleghi di lavoro con rapporto abituale e corrente nel medesimo contesto lavorativo;</li>
                  <li>Gli enti di proprietà di chi segnala o per i quali lavora il segnalante o che operano nel medesimo contesto lavorativo.</li>
                </ul>
              </>}
            />
        </div>
      </Section>

      <Section>
        <div className="container grid md:grid-cols-2 mx-auto py-12 lg:py-14">
          <article className="grid-row prose lg:prose-l max-w-full px-2 pr-10 pb-12">
            <h2 className="">
              D.Lgs. 10 marzo 2023, n. 24 in attuazione della Direttiva (UE) 2019/1937 riguardante la protezione delle persone che segnalano violazioni del diritto dell’Unione
            </h2>
            <p>Il <b>D.Lgs. 24/2023</b>, pubblicato in Gazzetta Ufficiale S.G. n. 63 del 15 marzo 2023, <b>recepisce nell’ordinamento giuridico italiano la normativa comunitaria</b> a tutela dei soggetti che segnalano attività illecite o frodi all’interno di un’organizzazione pubblica o privata, c.d. <i><b>“whistleblower”</b></i>.
            </p>
            <p>Rispetto alla normativa finora vigente, la legge di attuazione della Direttiva UE ha un raggio d’azione più ampio, in quanto:</p>
            <ul className="">
              <li className="" >Fa riferimento non soltanto alle violazioni <b>del diritto dell’Unione Europea, ma anche alle violazioni del diritto nazionale già specificate</b>, per il settore pubblico, con la L. 190/2012 e, per il settore privato, con la L. 179/2017;</li>
              <li className="" ><strong>Amplia la definizione di whistleblower</strong> a categorie che finora erano rimaste escluse dalla legislazione nazionale, come ex dipendenti e candidati a posizioni lavorative, lavoratori autonomi, liberi professionisti, consulenti, volontari, tirocinanti, azionisti e stakeholder;</li>
              <li className="" ><strong>Estende la protezione dei segnalanti nel settore privato</strong>, prima applicata soltanto alle aziende che adottano il modello di organizzazione e gestione 231, <strong>a tutte le aziende che nell’ultimo anno abbiano impiegato in media 50 o più dipendenti</strong>;</li>
              <li className="" >Si applica sia al personale delle <b>Pubbliche Amministrazioni</b> che a quello delle <b>Società soggette al controllo pubblico</b>, oltre che al <b>personale delle aziende che forniscono beni e servizi o eseguono lavori per la PA;</b></li>
              <li className="" ><strong>Vieta di rivelare l’identità del segnalante anche nel procedimento penale e contabile</strong>, così come in quello disciplinare.</li>
            </ul>
            <p>Il decreto attuativo assegna ad <b>ANAC</b> il ruolo di <b>unica Autorità nazionale competente, sia per il settore pubblico che per quello privato</b>. L’Autorità ha inoltre il potere di <b>irrogare sanzioni amministrative pecuniarie fino a 50.000 euro</b> in caso di mancato adeguamento o di violazioni delle prescrizioni normative.</p>
            <a href="https://www.normattiva.it/esporta/attoCompleto?atto.dataPubblicazioneGazzetta=2023-03-15&atto.codiceRedazionale=23G00032" target="_blank" rel="noopener noreferrer">Leggi il testo completo del D.Lgs. 10 marzo 2023, n. 24</a>
          </article>

          <article className="grid-row prose lg:prose-l max-w-full px-2 pb-12">
            <h2 className="">D.Lgs. 30 novembre 2017, n. 179 sul Whistleblowing. </h2>
            <p>Approvata il 15/11/2017 a tutela del dipendente pubblico e privato, prevede che sia predisposto <b>almeno un canale alternativo di segnalazione</b> idoneo a garantire, con <b>modalità informatiche</b>, la <b>riservatezza dell’identità del segnalante. </b>In seguito all’attuazione della Direttiva europea in Italia, <b>è stato abrogato l’art. 3 </b>della normativa.</p>
            <p>La nuova legge, composta da tre articoli, mira a fornire una tutela adeguata ai lavoratori e amplia la disciplina della legge Severino. Le norme modellano l'articolo 54 bis del Testo Unico del Pubblico Impiego, affermando che il dipendente che segnala condotte illecite o abusi nel contesto lavorativo non può subire sanzioni, demansionamenti, licenziamenti, trasferimenti o altre misure negative a causa della segnalazione.</p>
            <ul>
              <li><b>Reintegrazione nel posto di lavoro.</b> La legge prevede la reintegrazione del dipendente licenziato e dichiara nulli gli atti discriminatori o ritorsivi. L'onere di provare la motivazione estranea alla segnalazione delle misure discriminatorie è a carico dell'amministrazione.</li>
              <li><b>Sanzioni per gli atti discriminatori.</b> L'ANAC, a cui vengono segnalati atti discriminatori, può imporre sanzioni pecuniarie amministrative da 5.000 a 30.000 euro all'ente responsabile. Inoltre, sanzioni da 10.000 a 50.000 euro sono applicabili al responsabile che non verifica e analizza le segnalazioni ricevute.</li>
              <li><b>Segretezza dell’identità del denunciante.</b> È vietata la rivelazione dell'identità del dipendente che segnala atti discriminatori. Nel procedimento penale, la segnalazione è coperta secondo l'articolo 329 del codice di procedura penale, e l'accesso è limitato secondo la legge 7 agosto 1990, n. 241.</li>
              <li><b>Blocco della tutela.</b> Il dipendente che denuncia atti discriminatori perde il diritto alla tutela se condannato in sede penale per calunnia, diffamazione o altri reati connessi alla denuncia, o se è accertata la responsabilità civile per dolo o colpa grave.</li>
              <li><b>Estensione della nuova disciplina al settore privato.</b> Le nuove disposizioni si applicano non solo alle amministrazioni pubbliche, compresi gli enti pubblici economici e quelli di diritto privato sotto controllo pubblico, ma anche alle imprese che forniscono beni e servizi alla Pubblica Amministrazione.</li>
              <li><b>Giusta causa di rivelazione del segreto d'ufficio.</b> L'articolo 3 introduce la giusta causa di rivelazione del segreto d'ufficio nel settore pubblico o privato per perseguire l'interesse all'integrità delle amministrazioni nella prevenzione e repressione delle malversazioni.</li>
            </ul>

            <a href="http://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:legge:2017-11-30;179!vig=2021-07-06" target="_blank" rel="noopener noreferrer">Leggi il testo completo della Legge 179/2017</a>
          </article>

        </div>
      </Section>
      <Footer />
    </>
    );
  }
