import React from "react";

export default function Section(props) {
  let backgroundColor = '#FFFFFF';
  let textColor = '#000000';
  if (props.secondary) {
    backgroundColor = 'F3F4F6';
  }
  if (props.primary) {
    backgroundColor = '#939393';
    textColor = '#000000';
  }
  if (props.accent) {
    backgroundColor = '#f7b32a';
    textColor = '#ffffff';
  }

  return (
    <section className="px-4 flex-shrink-0" style={{backgroundColor: backgroundColor, color: textColor}}>
      {props.children}
    </section>
  );
}
