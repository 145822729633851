import React from 'react';
import {Link} from "react-router-dom";

import Header from 'components/Header';
import Footer from 'components/Footer';

const NotFound = () => {
    return (
      <>
        <Header />
        <div className="flex items-center justify-center m-20">
            <div className="min-h-md flex flex-col md:flex-row items-center justify-center px-5 mt-10 text-white border-accent border-4 ">
                <div className="max-w-lg m-10 bg-accent">
                    <div className="text-8xl mb-4 font-dark font-bold text-center pt-20">
                        <code className='bg-black text-accent'>404</code>
                    </div>
                    <p className="text-2xl font-light leading-normal px-10">
                        Opps! La pagina che stai cercando di visualizzare non è stata trovata
                    </p>
                    <p className="text-2xl text-black font-light leading-normal px-10 pt-5">
                        Cercavi un software per adempiere alla normativa sul Whistleblowing?
                    </p>
                    <div className="flex justify-center pb-10">
                        <Link to="/"
                              className="mt-4 block px-6 border py-2 bg-gray-light rounded hover:bg-black">
                            Torna alla Homepage
                        </Link>
                    </div>

                </div>
            </div>
        </div>
        <Footer />
      </>
    );
};

export default NotFound;
