import React from 'react';
import LogoWeb from "../assets/img/logo.svg";

const StructuredData = () => {
  // Organizzazione
  const organization = {
    name: "SafeReport",
    description: "Piattaforma sicura per il whistleblowing conforme alle normative ed alle linee guida ANAC",
    url: "https://safereport.it",
    logo: LogoWeb,
    telephone: "+390957791192",
    email: "info@safereport.it",
    // facebookUrl: "URL_PAGINA_FACEBOOK",
    // twitterUrl: "URL_PAGINA_TWITTER",
    // otherSocialMediaUrl: "URL_ALTRO_SOCIAL_MEDIA"
  };

  // Sito Web
  const website = {
    name: "SafeReport WhistleBlowing Software conforme alla Direttiva UE 2019/1937",
    description: "SafeReport: La piattaforma di whistleblowing che rispetta le normative ISO 37001, ISO 37002, ISO 27001, UE 2019/1937 e GDPR. Sicura, anonima, conforme.",
    url: "https://safereport.it",
  };

  // Breadcrumb List
  const breadcrumbList = [
    { "@id": website.url, name: "Home" },
    { "@id": "https://demo.safereport.it", name: "Demo" },
    { "@id": "https://safereport.it/Privacy", name: "Privacy Policy" },
    { "@id": "https://safereport.it/Whistleblowing-Policy", name: "Whistleblowing Policy" },
    { "@id": "https://safereport.it/normativa-whistleblowing", name: "Normativa Whistleblowing" },
    { "@id": "https://safereport.it/faq", name: "Contattaci" }
  ];

  // Software
  const software = {
    name: "SafeReport WhistleBlowing Software",
    description: "SafeReport: La soluzione chiavi in mano per il whistleblowing, basata su GlobaLeaks, gestita da GL S.r.l.s. in Conformità alle ISO 37001, ISO 37002, ISO 27001, GDPR.",
    operatingSystem: "Any",
    applicationCategory : "WebApplication",
    offers : { "@type" : "Offer", "price" : "500.00", "priceCurrency" : "EUR"},
  };

  // Recensioni
  const reviews = [
    {
      author: { "@type": "Organization", name: "SiRistora Food&Global Service" },
      reviewRating: { "@type": "Rating", ratingValue: "5", bestRating: "5", worstRating: "1" },
      reviewBody: "Il nostro team ha trovato SafeReport estremamente utile per gestire segnalazioni di illeciti in modo sicuro e conforme alle normative. Altamente raccomandato."
    }
    // Aggiungi altre recensioni se necessario
  ];

  const complianceList = [
    { "@type": "Event", name: "Adeguamento a ISO 27001", startDate: "2023-12-16", "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode", "location": { "@type": "VirtualLocation", "url": "https://safereport.it/"} },
    { "@type": "Event", name: "Adeguamento a ISO 37001", startDate: "2023-12-16", "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode", "location": { "@type": "VirtualLocation", "url": "https://safereport.it/"} },
    { "@type": "Event", name: "Adeguamento a ISO 37002", startDate: "2023-12-16", "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode", "location": { "@type": "VirtualLocation", "url": "https://safereport.it/"} },
    { "@type": "Event", name: "Adeguamento a GDPR", startDate: "2023-12-16",  "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode", "location": { "@type": "VirtualLocation", "url": "https://safereport.it/"} },
    // Aggiungi altre date di conformitÃ  se necessario
  ];

  // Certificazioni
  const awards = [
    { "@type": "Award", name: "Certificazione ISO 9001:2015", description: "Riconoscimento per eccellenza nei processi aziendali." },
    { "@type": "Award", name: "Certificazione ISO 27001", description: "Standard internazionale per la gestione della sicurezza delle informazioni." },
  ];

  // Evento
  const eventStartDate = "2023-12-16T14:00";
  const eventEndDate = "2024-12-31T16:00";
  const eventUrl = website.url;
  const event = {
    name: "Adeguamento Whistleblowing: per le Pubbliche Amministrazioni ed Aziende",
    description: "Con SafeReport puoi adempiere a tutte le normative sul whistleblowing con pochi click.",
    startDate: eventStartDate,
    endDate: eventEndDate,
    url: eventUrl,
  };

  return (
    <>
      {/* Script per l'organizzazione */}
      <script type="application/ld+json">{JSON.stringify({ "@context": "http://schema.org", "@type": "Organization", ...organization })}</script>

      {/* Script per il sito web */}
      <script type="application/ld+json">{JSON.stringify({ "@context": "http://schema.org", "@type": "WebSite", ...website })}</script>

      {/* Script per la breadcrumb list */}
      <script type="application/ld+json">{JSON.stringify({ "@context": "http://schema.org", "@type": "BreadcrumbList", itemListElement: breadcrumbList.map((item, index) => ({ "@type": "ListItem", position: index + 1, item })) })}</script>
      {/* Script per il software */}
      <script type="application/ld+json">{JSON.stringify({ "@context": "http://schema.org", "@type": "SoftwareApplication", ...software, aggregateRating: { "@type": "AggregateRating", ratingValue: "5", reviewCount: reviews.length }, hasPart: complianceList, award: awards })}</script>

      {/* Script per le recensioni */}
      {reviews.map((review, index) => (
        <script key={index} type="application/ld+json">{JSON.stringify({ "@context": "http://schema.org", "@type": "Review", itemReviewed: { "@type": "Product", image: LogoWeb, name: software.name, offers: software.offers }, ...review })}</script>
      ))}

      {/* Script per l'evento */}
      <script type="application/ld+json">{JSON.stringify({ "@context": "http://schema.org", "@type": "Event", ...event })}</script>
    </>
  );
};

export default StructuredData;
