import React from 'react';
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import Timing from 'components/Timing.js';
import Header from 'components/Header.js';
import Section from 'components/Section.js';

export default function Privacy() {
  return (
    <>
      <Header />
      <Section>
        <div className="container mt-3 mx-auto py-12 lg:py-14 xl:py-20">
          <div className="prose rich-text-block max-w-full text-left" >
          <h1>Privacy Policy | INFORMATIVA SITO WEB</h1>
          <p>In questa pagina si descrivono le modalità di gestione del sito www.splitweb.it in riferimento al trattamento dei dati personali dei visitatori che lo consultano. La presente informativa è resa ai sensi dell’art. 13 del Regolamento UE 2016/679 (Regolamento in materia di protezione dei dati personali) a coloro che interagiscono con i servizi web di GL S.r.l.s. accessibili dal sito.L’informativa è resa solo per il sito di GL S.r.l.s. e non anche per altri siti web eventualmente consultati dal visitatore.</p>
          <h2>Titolare del trattamento</h2>
          <p>Il Titolare del trattamento è GL S.r.l.s., in persona del Legale Rappresentante, con sede legale in Via Palermo, 3 – 95014 Giarre (CT), C.F. e P.IVA 05362310871, mail: info@splitweb.it, pec: glsrls@pec.cgn.it tel: 095.7791192.</p>
          <h2>Responsabile della Protezione dei Dati</h2>
          <p>Il Titolare ha nominato un Responsabile della protezione dei dati (“Data Protection Officer” o “DPO”), che Lei potrà contattare per l’esercizio dei Suoi diritti, nonché per ricevere qualsiasi informazione relativa agli stessi e/o alla presente Informativa:</p>
          <ul>
            <li>scrivendo a GL S.r.l.s., Via Palermo, 3 – 95014 Giarre (CT), – Att.ne Data Protection Officer;</li>
            <li>inviando una e-mail all’indirizzo info@splitweb.it – Att.ne Data Protection Officer;</li>
            <li>inviando un messaggio di posta elettronica certificata all’indirizzo PEC glsrls@pec.cgn.it – Att.ne Data Protection Officer</li>
          </ul>
          <h2>Tipi di dati trattati e finalità del trattamento</h2>
          <h3>Dati di navigazione</h3>
          <p>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del normale esercizio e per la sola durata della connessione, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.</p>
          <p>Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.</p>
          <p>In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione. I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito: salva questa eventualità, allo stato i dati sui contatti web non persistono per più di 7 giorni.</p>
          <h3>Dati forniti volontariamente dall’utente</h3>
          <p>L'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva. Detti dati verranno trattati esclusivamente al fine di evadere la richiesta inoltrata.</p>
          <p>Specifiche informative di sintesi verranno progressivamente riportate o visualizzate nelle pagine del sito predisposte per questi particolari servizi a richiesta. </p>
          <p>Si rappresenta in ogni caso che una volta conferiti i dati personali, il trattamento può essere necessario per adempiere ad obblighi di legge a cui GL S.r.l.s. è soggetta. Questo trattamento risulta legittimo ai sensi dell’art. 6(1)(c) del Regolamento UE 2016/679 e non è possibile opporsi, trattandosi di un trattamento derivante da obblighi di legge.</p>
          <h3>Cookies</h3>
          <p>Un cookie è un piccolo "file dati" che alcuni siti web, mentre vengono visitati, possono inviare all'indirizzo dell'utente che li sta navigando al fine di tracciare il suo percorso all'interno del sito e raccogliere dati in forma esclusivamente anonima per migliorare l'offerta e la fruibilità del sito stesso. È possibile anche rifiutare automaticamente la ricezione dei cookies attivando l'apposita opzione del browser: il mancato utilizzo dei cookies, tuttavia, potrebbe comportare difficoltà nell'interazione con il sito della Società. Per ulteriori informazioni inerenti ai cookie si rinvia alla specifica informativa pubblicata nella sezione “Cookie” del presente sito.  </p>
          <h2>Luogo del trattamento</h2>
          <p>I dati personali raccolti a seguito della consultazione del sito sono trattati nel server dell'Azienda.</p>
          <p>I dati personali raccolti mediante l’invio facoltativo, esplicito e volontario di messaggi agli indirizzi di contatto della GL S.r.l.s., sono trattati presso la sede dell’Azienda stessa in Via Palermo, 3 – 95014 Giarre (CT). Potranno altresì essere trattati presso le sedi dei Responsabili del trattamento espressamente nominati, nei limiti in cui ciò si renderà necessario.</p>
          <h2>Facoltatività del conferimento dei dati personali</h2>
          <p>A parte quanto specificato per i dati di navigazione, l'utente è libero di fornire i dati personali riportati negli appositi moduli elettronici di richiesta, nelle sezioni del sito web predisposte per richieste di informazioni/assistenza. Si evidenzia in ogni caso che il loro mancato conferimento può comportare l'impossibilità di ottenere quanto richiesto</p>
          <h2>Modalità del trattamento</h2>
          <p>Il trattamento dei Suoi dati personali avverrà in maniera lecita e corretta mediante strumenti automatizzati, informatici e telematici, con logiche strettamente correlate alle finalità indicate e, comunque, con modalità idonee a garantirne la sicurezza e la riservatezza in conformità alle disposizioni previste dall’articolo 32 GDPR.</p>
          <p>I suoi dati saranno trattati per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti. In ogni caso, trattandosi di trattamenti svolti per la fornitura di Servizi, GL S.r.l.s. tratterà i dati personali fino al tempo permesso dalla normativa Italiana a tutela dei propri interessi (Art. 2946 c.c. e ss.).</p>
          <p>Maggiori informazioni in merito ai periodi di conservazione dei dati e ai criteri utilizzati per determinare tali periodi possono essere richieste scrivendo al Titolare o al Responsabile della Protezione Dati.</p>
          <h2>Diritti degli interessati</h2>
          <p>In relazione ai trattamenti descritti nella presente Informativa, in qualità di interessato Lei potrà, alle condizioni previste dal GDPR, esercitare i diritti sanciti dagli articoli da 15 a 21 del GDPR e, in particolare, il diritto di:</p>
          <ul>
            <li>ottenere conferma che sia o meno in corso un trattamento di dati personali che La riguardano e, in tal caso, ottenere l'accesso ai Suoi dati personali – compresa una copia degli stessi – e la comunicazione, tra le altre, delle seguenti informazioni: finalità del trattamento, categorie di dati personali trattati, destinatari cui questi sono stati o saranno comunicati, periodo di conservazione dei dati, diritti dell’interessato (diritto di accesso– articolo 15 GDPR);</li>
            <li>ottenere, senza ingiustificato ritardo, la rettifica dei dati personali inesatti che La riguardano e/o l’integrazione dei dati personali incompleti (diritto di rettifica– articolo 16 GDPR);</li>
            <li>ottenere, senza ingiustificato ritardo, la cancellazione dei dati personali che La riguardano (diritto alla cancellazione – articolo 17 GDPR);</li>
            <li>ottenere la limitazione del trattamento (diritto di limitazione di trattamento– articolo 18 GDPR);</li>
            <li>ricevere in un formato strutturato, di uso comune e leggibile da un dispositivo automatico, i dati personali che La riguardano, trasmetterli a un altro titolare senza impedimenti e, ove tecnicamente fattibile, ottenere che i Suoi dati personali siano trasmessi direttamente dalla Società ad altro titolare, qualora il trattamento si basi sul consenso e sia effettuato con mezzi automatizzati  (diritto alla portabilità dei dati– articolo 20 GDPR);</li>
            <li>opporsi al trattamento dei dati personali che La riguardano, salvo che sussistano motivi legittimi per il Titolare di continuare il trattamento (diritto di opposizione – articolo 21 GDPR);</li>
            <li>proporre reclamo all’Autorità Garante per la protezione dei dati personali, Piazza di Montecitorio n. 121, 00186, Roma (RM).</li>
          </ul>
          <p>Per l’esercizio dei Suoi diritti, per la revoca del consenso o per ricevere ulteriori informazioni in ordine al suddetto trattamento, potrà contattare il Titolare scrivendo al seguente indirizzo e-mail info@splitweb.it o all’indirizzo postale presso la sede di GL S.r.l.s., in Via Palermo, 3 – 95014 Giarre (CT).</p>
          <p>La Sua richiesta sarà riscontrata nel minor tempo possibile e, comunque, nei termini di cui al GDPR, ovvero entro un mese, salvo casi di particolare complessità, per cui potrebbe impiegarsi massimo 3 mesi. In ogni caso, il Titolare provvederà a spiegare all’utente il motivo dell’attesa entro un mese dalla sua richiesta.</p>
          <p>L’esito della richiesta dell’utente verrà fornito per iscritto o su formato elettronico.</p>
          <p>La informiamo che qualora il Titolare nutra dubbi circa l’identità della persona fisica che presenta la richiesta, potrà richiedere ulteriori informazioni necessarie a confermare l’identità dell’interessato.</p>
          <p>L’esercizio dei Suoi diritti in qualità di interessato è gratuito ai sensi dell’articolo 12 GDPR. Tuttavia, nel caso di richieste manifestamente infondate o eccessive, anche per la loro ripetitività, il Titolare potrebbe addebitarLe un contributo spese ragionevole, alla luce dei costi amministrativi sostenuti per gestire la Sua richiesta, o negare la soddisfazione della Sua richiesta.</p>
          <p>
            <a className="text-blue-600 visited:text-purple-600 block" href="/docs/Elenco_dei_soggetti_a_cui_i_dati_possono_essere_comunicati_2023.pdf" target="_BLANK" rel="noopener noreferrer">ELENCO DEI SOGGETTI A CUI I DATI POSSONO ESSERE COMUNICATI</a>
            <a className="text-blue-600 visited:text-purple-600 block" href="/docs/Informativa_nei_confronti_dei_Clienti_2023.pdf" target="_BLANK" rel="noopener noreferrer">INFORMATIVA NEI CONFRONTI DEI CLIENTI</a>
            <a className="text-blue-600 visited:text-purple-600 block" href="/docs/Informativa_nei_confronti_dei_Fornitori_2023.pdf" target="_BLANK" rel="noopener noreferrer">INFORMATIVA NEI CONFRONTI DEI FORNITORI</a>
          </p>
          </div>
        </div>
      </Section>
      <Footer />
    </>
    );
  }
