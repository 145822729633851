import React from 'react';
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import Timing from 'components/Timing.js';
import Header from 'components/Header.js';
import Section from 'components/Section.js';

export default function Privacy() {
  return (
    <>
      <Header />
      <Section>
        <div className="container mt-3 mx-auto py-12 lg:py-14 xl:py-20">
          <div className="prose rich-text-block max-w-full text-left" >
            <h1>Whistleblowing Policy | Procedura per le segnalazioni di illeciti e irregolarità</h1>
            <p>Con l’espressione whistleblower si fa riferimento al dipendente di un’amministrazione che segnala violazioni o irregolarità commesse ai danni dell’interesse pubblico agli organi legittimati ad intervenire.</p>
            <p>La segnalazione (cd. whistleblowing), in tale ottica, è un atto di manifestazione di senso civico, attraverso cui il whistleblower contribuisce all’emersione e alla prevenzione di rischi e situazioni pregiudizievoli per l’amministrazione di appartenenza e, di riflesso, per l’interesse pubblico collettivo. Il whistleblowing è la procedura volta ad incentivare le segnalazioni e a tutelare, proprio in ragione della sua funzione sociale, il whistleblower.</p>

            <h2>FONTE NORMATIVA E NATURA DELL’ISTITUTO</h2>
            <p>L’art. 1, comma 51, della legge 190/2012 (cd. legge anticorruzione) ha inserito un nuovo articolo, il 54 bis1, nell’ambito del d.lgs. 165/2001, rubricato “tutela del dipendente pubblico che segnala illeciti”, in virtù del quale è stata introdotta nel nostro ordinamento una misura finalizzata a favorire l’emersione di fattispecie di illecito, nota nei paesi anglosassoni come whistleblowing.</p>

            <h2>SCOPO E FINALITA’ DELLA PROCEDURA</h2>
            <p>Scopo del presente documento è quello di rimuovere i fattori che possono ostacolare o disincentivare il ricorso all’istituto, quali i dubbi e le incertezze circa la procedura da seguire e i timori di ritorsioni o discriminazioni.</p>

            <h2>OGGETTO DELLA SEGNALAZIONE</h2>
            <p>Non esiste una lista tassativa di reati o irregolarità che possono costituire l’oggetto del whistleblowing. Vengono considerate rilevanti le segnalazioni che riguardano comportamenti, rischi, reati o irregolarità, consumati o tentati, a danno dell’interesse pubblico. Il whistleblowing non riguarda lamentele di carattere personale del segnalante o rivendicazioni/istanze che rientrano nella disciplina del rapporto di lavoro o rapporti col superiore gerarchico o colleghi, per le quali occorre fare riferimento alla disciplina e alle procedure di competenza del Servizio Personale e del Comitato Unico di Garanzia.</p>

            <h2>CONTENUTO DELLE SEGNALAZIONI</h2>
            <p>Il whistleblower deve fornire tutti gli elementi utili a consentire agli uffici competenti di procedere alle dovute ed appropriate verifiche ed accertamenti a riscontro della fondatezza dei fatti oggetto di segnalazione. Le segnalazioni anonime, vale a dire prive di elementi che consentano di identificare il loro autore, anche se recapitate tramite le modalità previste dal presente documento, non verranno prese in considerazione nell’ambito delle procedure volte a tutelare il dipendente pubblico che segnala illeciti, ma verranno trattate alla stregua delle altre segnalazioni anonime e prese in considerazione per ulteriori verifiche solo se relative a fatti di particolare gravità e con un contenuto che risulti adeguatamente dettagliato e circostanziato. Resta fermo il requisito della veridicità dei fatti o situazioni segnalati, a tutela del denunciato.</p>

            <h2>MODALITA’ E DESTINATARI DELLA SEGNALAZIONE</h2>
            <p>L’Ente mette a disposizione dei propri dipendenti e dei propri collaboratori un applicativo software accessibile ai dipendenti attraverso la intranet. L’applicativo software garantisce come da linee guida dell’ANAC, assoluta riservatezza e crittografia del segnalante e della segnalazione, conosciute esclusivamente dal soggetto ricevente. Qualora il whistleblower rivesta la qualifica di pubblico ufficiale, l’invio della segnalazione ai suddetti soggetti non lo esonera dall’obbligo di denunciare alla competente Autorità giudiziaria i fatti penalmente rilevanti e le ipotesi di danno erariale.</p>

            <h2>ATTIVITA’ DI VERIFICA DELLA FONDATEZZA DELLA SEGNALAZIONE</h2>
            <p>La gestione e la verifica sulla fondatezza delle circostanze rappresentate nella segnalazione sono affidate al Responsabile per la prevenzione della corruzione che vi provvede nel rispetto dei principi di imparzialità e riservatezza effettuando ogni attività ritenuta opportuna, inclusa l’audizione personale del segnalante e di eventuali altri soggetti che possono riferire sui fatti segnalati. A tal fine, il Responsabile per la prevenzione della corruzione può avvalersi del supporto e della collaborazione delle competenti strutture aziendali e, all’occorrenza, di organi di controllo esterni all’Ente (tra cui Guardia di Finanza, Direzione Provinciale del Lavoro, Comando Vigili Urbani, Agenzia delle Entrate).</p>

            <h2>FORME DI TUTELA DEL WHISTLEBLOWER (ai sensi dell’art. 54 bis d.lgs. 165/2001 e del Piano Nazionale Anticorruzione)</h2>
            <h3>A) Obblighi di riservatezza sull’identità del whistleblower e sottrazione al diritto di accesso della segnalazione</h3>
            <p>Ad eccezione dei casi in cui sia configurabile una responsabilità a titolo di calunnia e di diffamazione ai sensi delle disposizioni del codice penale o dell’art. 2043 del codice civile e delle ipotesi in cui l’anonimato non è opponibile per legge, (es. indagini penali, tributarie o amministrative, ispezioni di organi di controllo) l’identità del whisteblower viene protetta in ogni contesto successivo alla segnalazione. Per quanto concerne, in particolare, l’ambito del procedimento disciplinare, l’identità del segnalante può essere rivelata all’autorità disciplinare e all’incolpato solo nei casi in cui: vi sia il consenso espresso del segnalante; la contestazione dell’addebito disciplinare risulti fondata, in tutto o in parte, sulla segnalazione e la conoscenza dell’identità del segnalante risulti assolutamente indispensabile alla difesa dell’incolpato, sempre che tale circostanza venga da quest’ultimo dedotta e comprovata in sede di audizione o mediante la presentazione di memorie difensive.</p>
            <h3>B) Divieto di discriminazione nei confronti del whistleblower</h3>
            <p>Nei confronti del dipendente che effettua una segnalazione ai sensi della presente procedura non è consentita, né tollerata alcuna forma di ritorsione o misura discriminatoria, diretta o indiretta, avente effetti sulle condizioni di lavoro per motivi collegati direttamente o indirettamente alla denuncia. Resta ferma la facoltà del dipendente di rivolgersi direttamente al Comitato Unico di Garanzia che provvederà a darne tempestiva comunicazione al Responsabile per la prevenzione della corruzione.</p>

            <h2>RESPONSABILITA’ DEL WHISTLEBLOWER</h2>
            <p>La presente procedura lascia impregiudicata la responsabilità penale e disciplinare del whistleblower nell’ipotesi di segnalazione calunniosa o diffamatoria ai sensi del codice penale e dell’art. 2043 del codice civile. Sono altresì fonte di responsabilità, in sede disciplinare e nelle altre competenti sedi, eventuali forme di abuso della presente policy, quali le segnalazioni manifestamente opportunistiche e/o effettuate al solo scopo di danneggiare il denunciato o altri soggetti, e ogni altra ipotesi di utilizzo improprio o di intenzionale strumentalizzazione dell’istituto oggetto della presente procedura.</p>
          </div>
        </div>
      </Section>
      <Footer />
    </>
    );
  }
