import React from 'react';
import bannerStyle from './bannerStyle';
import CookieBannerPreferences from './CookieBannerPreferences';

class CookieBannerContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPreferences: false,
      smallMediaMatches: window.matchMedia('(max-width: 500px)').matches,
      smallerMediaMatches: window.matchMedia('(max-width: 330px)').matches
    };
  }

  componentDidMount() {

    const smallHandler = (e) => {
      this.setState({ smallMediaMatches: e.matches });
    };
    const smallerHandler = (e) => {
      this.setState({ smallerMediaMatches: e.matches });
    };
    window.matchMedia('(max-width: 500px)').addEventListener('change', smallHandler);
    window.matchMedia('(max-width: 330px)').addEventListener('change', smallerHandler);
  }

  render() {
    const {
      styles = {},
      className = '',
      message = 'No text',
      policyLink = '/privacy',
      imprintLink = '/cookie',
      privacyPolicyLinkText = 'Privacy Policy',
      imprintLinkText = 'Cookie Policy',
      acceptButtonText = 'Accetto',
      managePreferencesButtonText = 'Preferenze',
      savePreferencesButtonText = 'Salva e Chiudi',
      onConfirm = () => { this.setState({ showPreferences: false }) },
      onAcceptAll = () => { this.setState({ showPreferences: false })  },
      statisticsDescription = '',
      preferencesDescription = '',
      marketingDescription = '',
      necessaryDescription = ''
    } = this.props;

    const { showPreferences } = this.state;

    const {
      dialog: dialogStyle,
      container: containerStyle,
      message: messageStyle,
      policy: policyStyle,
      imprint: imprintStyle,
      button: buttonStyle,
    } = { ...bannerStyle, ...styles };

    const btn = 'btn';
    const btnPrimary = 'btn btn-primary';
    const btnOutlineSecondary = 'btn btn-outline-secondary';

    return (
      <div className={`react-cookie-law-dialog ${className}`} style={dialogStyle}>
        <div className="react-cookie-law-container" style={containerStyle}>
          <div className="react-cookie-law-msg" style={messageStyle}>
            <h4 className='text-xl font-bold'>Privacy & Cookie GDPR Compliant</h4>
            <p>{message}</p>
            </div>

          {
            showPreferences && (<CookieBannerPreferences {...this.props} />)
          }

          <div className="react-cookie-law-button-wrapper" style={bannerStyle.buttonWrapper(this.state.smallMediaMatches)}>
            {
              showPreferences
                ? (
                  <button type="button"
                          className={`react-cookie-law-save-btn ${btnOutlineSecondary}`}
                          style={buttonStyle}
                          onClick={() => onConfirm()}>
                    <span>{savePreferencesButtonText}</span>
                  </button>
                )
                : (
                  <button type="button"
                          className={`react-cookie-law-manage-btn ${btnOutlineSecondary}`}
                          style={buttonStyle}
                          onClick={() => this.setState({ showPreferences: true })}>
                    <span>{managePreferencesButtonText}</span>
                  </button>
                )
            }
            <button type="button"
                    className={`react-cookie-law-accept-btn ${btnPrimary}`}
                    style={buttonStyle}
                    onClick={() => onAcceptAll()}>
              <span>{acceptButtonText}</span>
            </button>

          </div>

          <div className="react-cookie-law-links" style={bannerStyle.linkContainer(this.state.smallerMediaMatches)}>

            <a href={policyLink}
               className="react-cookie-law-policy"
               style={policyStyle}
               target="_blank"
               rel="noopener noreferrer"
            >
              {privacyPolicyLinkText}
            </a>

            <a href={imprintLink}
               className="react-cookie-law-imprint"
               style={imprintStyle}
               target="_blank"
               rel="noopener noreferrer"
            >
              {imprintLinkText}
            </a>

          </div>

        </div>
      </div>
    );
  }
}

export default CookieBannerContent;
