import React from 'react';

export default function ImageTextSection(props) {
  return (
    <div className={`flex flex-col mb-6 lg:flex-row w-full gap-10 lg:gap-20 items-center ${props.reverse ? 'lg:!flex-row-reverse' : 'flex-col'}`}>
      <div className="flex-1 flex flex-col items-center lg:items-start">
        <div className="md:prose-md rich-text-block">
          <h3 className='text-2xl font-bold leading-8 mb-4 text-accent'>{props.title}</h3>
          {typeof props.content === 'string' && (
            <p>{props.content}</p>
          )}
          {typeof props.content !== 'string' && props.content}
        </div>
      </div>
      <div className="flex-1 w-full">
        <div className="prose-img:rounded-xl">
          <img className='object-cover w-full h-96' alt={props.title}
            src={props.image}
          />
        </div>
      </div>
    </div>
  );
}
