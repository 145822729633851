import React from 'react';
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import Timing from 'components/Timing.js';
import Header from 'components/Header.js';
import Section from 'components/Section.js';

export default function Privacy() {
  return (
    <>
      <Header />
      <Section>
        <div className='container pt-20 mx-auto prose rich-text-block max-w-full text-center'>
          <h1>FAQ | Frequently Asked Questions</h1>
        </div>
      </Section>
      <Section>
        <div className="prose py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <p>Se non trovi ciò di cui hai bisogno, contattaci via email all'indirizzo info@safereport.it o chiamaci al (+39) 349 2342848, insieme troveremo la soluzione per soddisfare le tue esigenze.</p>
            <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
                <div>
                    <div className="mb-10">
                        <h3 className="flex items-center mb-4 text-lg text-gray-900 dark:text-white">
                            <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                            Cos'è il software di whistleblowing?
                        </h3>
                        <p className="text-gray-500 dark:text-gray-400">
                          Il software di whistleblowing è una piattaforma che consente agli utenti di segnalare in modo anonimo e sicuro comportamenti non etici, frodi o altre violazioni all'interno di un'organizzazione.
                        </p>
                    </div>
                    <div className="mb-10">
                        <h3 className="flex items-center mb-4 text-lg text-gray-900 dark:text-white">
                            <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                            Come possono i miei utenti inviare una segnalazione?
                        </h3>
                        <p className="text-gray-500 dark:text-gray-400">
                          All'interno del tuo pannello di amministrazione avrai un link da fornirgli oppure integrarlo in una pagina del tuo attuale sito internet.
                        </p>
                    </div>
                    <div className="mb-10">
                        <h3 className="flex items-center mb-4 text-lg text-gray-900 dark:text-white">
                            <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                            Le segnalazioni sono davvero anonime?
                        </h3>
                        <p className="text-gray-500 dark:text-gray-400">
                          Sì, le segnalazioni sono completamente anonime. Il sistema è progettato per proteggere l'identità del segnalatore e garantire la massima riservatezza.
                        </p>
                    </div>
                    <div className="mb-10">
                        <h3 className="flex items-center mb-4 text-lg text-gray-900 dark:text-white">
                            <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                            Quali misure sono in atto per garantire la sicurezza delle segnalazioni?
                        </h3>
                        <p className="text-gray-500 dark:text-gray-400">
                          La piattaforma utilizza crittografia avanzata e altre misure di sicurezza per proteggere le segnalazioni. L'accesso è limitato al personale autorizzato per garantire la sicurezza dei dati.
                        </p>
                    </div>
                </div>
                <div>
                    <div className="mb-10">
                        <h3 className="flex items-center mb-4 text-lg text-gray-900 dark:text-white">
                            <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                            Come viene gestito il follow-up delle segnalazioni?
                        </h3>
                        <p className="text-gray-500 dark:text-gray-400">
                          Dopo la segnalazione, l'utente viene dotato di un codice univoco di 20 caratteri, non visibile a te. Grazie a quel codice gli sarà possibile accedere alla segnalazione, mandare ulteriori prove, e comunicare con te.
                        </p>
                    </div>
                    <div className="mb-10">
                        <h3 className="flex items-center mb-4 text-lg text-gray-900 dark:text-white">
                            <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                            Il vostro software è conforme alle normative D.lgs. 231/2007 e D.lgs. 24/
                        </h3>
                        <p className="text-gray-500 dark:text-gray-400">
                          Assolutamente sì. Il nostro software è progettato e costantemente aggiornato per garantire la piena conformità con le disposizioni del D.lgs. 231/2007 e D.lgs. 24/2023.
                        </p>
                    </div>
                    <div className="mb-10">
                        <h3 className="flex items-center mb-4 text-lg text-gray-900 dark:text-white">
                            <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                            Come posso richiedere una demo del vostro software?
                        </h3>
                        <p className="text-gray-500 dark:text-gray-400">
                          Se vuoi provare il nostro software, invia una mail a info@safereport.it chiedendoci una demo, ti risponderemo entro 24 ore con tutti i dettagli necessari.
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </Section>
      <Footer />
    </>
    );
  }
