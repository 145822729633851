import React from 'react';
import "../footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import LogoWeb from "../assets/img/logo-white.svg";
import GlobaLeaks from "../assets/img/globaleaks-logo-white.png";
import SGS from '../assets/img/SGS_ISO9001_2015.svg';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="flex-1 bg-black text-white text-sm">
      <div className="px-4 flex flex-col lg:flex-row items-center gap-12 lg:gap-3 container mx-auto py-12 lg:py-14 xl:py-20">
        <div className="flex-1 w-full lg:w-auto flex items-center flex-no-shrink text-white">
          <img className="h-20 md:h-50 inline" src={LogoWeb} alt="SafeReport WhistleBlowing Software" title="SafeReport WhistleBlowing Software" />
          <span className="ml-4 text-3xl align-bottom">SafeReport</span>
        </div>
        <div className="flex-1 w-full lg:w-auto">
          <p>SafeReport è una soluzione software per il Whistleblowing</p>
          <p>che rispetta le Linee guida dell’ANAC e conforme a:</p>
          <ul className='list-disc list-inside'>
            <li>ISO 37001 | ISO 37002 | ISO 27001</li>
            <li>Direttiva UE 2019/1937 | Regolamento GDPR</li>
          </ul>
          <img className="h-20 md:h-20" src={GlobaLeaks} alt="GlobaLeaks WhistleBlowing" title="GlobaLeaks WhistleBlowing" />
        </div>
        <div className="flex-1 w-full flex flex-col gap-12 lg:gap-6 justify-end">
          <div className="flex flex-col lg:flex-row items-start lg:items-center lg:justify-end gap-12 lg:gap-14 w-full lg:w-auto max-w-[100vw]">
            <ul className="flex flex-col lg:flex-wrap lg:flex-row lg:items-center gap-6">
              <li className="border-b-2  hover:border-b-4 hover:text-accent">
                <Link to="/" className="block py-1.5 font-light">Home</Link>
              </li>
              <li className="border-b-2 hover:border-b-4 hover:text-accent">
                <Link to="/normativa-whistleblowing" className="block py-1.5 font-light">Normativa</Link>
              </li>
              <li className="border-b-2 hover:border-b-4 hover:text-accent">
                <Link to="/informativa-whistleblowing" className="block py-1.5 font-light">Informativa</Link>
              </li>
              {/* <li className="border-b-2" style={{borderColor: 'transparent'}}>
                <Link to="/chi-siamo" className="block py-1.5 font-light">Chi Siamo</Link>
              </li>
              <li className="border-b-2" style={{borderColor: 'transparent'}}>
                <Link to="/caratteristiche" className="block py-1.5 font-light">Caratteristiche</Link>
              </li>
              <li className="border-b-2" style={{borderColor: 'transparent'}}>
                <Link to="/demo" className="block py-1.5 font-light">Demo</Link>
              </li> */}
            </ul>
            {/* <ul className="flex items-center gap-x-7 lg:gap-x-4 gap-y-2 flex-wrap">
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/">
                  <FontAwesomeIcon icon={brands('facebook')} size='lg' />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/">
                  <FontAwesomeIcon icon={brands('linkedin')} size='lg' />
                </a>
              </li>
            </ul> */}
          </div>
          <span className="lg:text-right">
            SafeReport è un progetto di <a target="_blank" rel="noopener noreferrer" className="underline hover:text-accent" href="//www.splitweb.it">SPLIT ® | GL S.r.l.s.</a>. realizzato grazie a <a target="_blank" rel="noopener noreferrer" className="underline hover:text-accent" href="//www.globaleaks.org/">GlobaLeaks</a> software Open Source distribuito in CC BY-SA 4.0.
            <p>GlobaLeaks è riconosciuto <a target="_blank" rel="noopener noreferrer" className="underline hover:text-accent" href="https://digitalpublicgoods.net/registry/globaleaks.html">Digital Public Good</a> dalla <a target="_blank" rel="noopener noreferrer" className="underline hover:text-accent" href="https://digitalpublicgoods.net/">Digital Public Goods Alliance</a> e raccomandato da <a target="_blank" rel="noopener noreferrer" className="underline hover:text-accent" href="https://www.u4.no/publications/overview-of-whistleblowing-software.pdf">Transparency International</a>.</p>
          </span>
        </div>
      </div>
      <div className="flex flex-row items-center text-center p-4 mx-auto" style={{backgroundColor: '#939393'}}>
        <div className='basis-1/4'>
          <img className="float-right" src={SGS} width="100" alt="SGS ISO 9001:2015"/>
        </div>
        <div className='basis-1/2'>
          <p>GL S.r.l.s. P.IVA 05362310871 | © 2023 All Right Reserved</p>
          <a className="font-bold text-accent" href="https://www.splitweb.it/"> SPLIT ® Web Agency Giarre Catania</a> - <Link className="underline hover:text-accent" to="/Privacy">Privacy Policy</Link> - <Link className="underline hover:text-accent" to="/Whistleblowing-Policy">Whistleblowing Policy</Link>
        </div>
      </div>
    </footer>
  );
}
