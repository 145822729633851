import React from 'react';
import Header from "../components/Header.js";
import Body from "../components/Body.js";
import Footer from "../components/Footer.js";
import StructuredData from 'components/StructuredData.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import logo from "../assets/img/logo.svg";
import Timing from 'components/Timing.js';

import Form from '../components/Form';
const formConfig = {
  api: `${process.env.REACT_APP_API}`,
  title: 'Hai bisogno di altre informazioni?',
  description: 'Compila questo modulo e ti ricontattiamo in 24 ore.',
  successMessage: 'Messaggio inviato correttamente.',
  errorMessage: 'Si è verificato un errore.',
  fields: {
    name: '',
    email: '',
    message: ''
  },
  fieldsConfig: [
    { id: 1, label: 'Nome e Cognome', fieldName: 'name', type: 'text', placeholder: 'Nome e Cognome', isRequired: true, klassName: '' },
    { id: 2, label: 'Email', fieldName: 'email', type: 'email', placeholder: ' Your Email', isRequired: true, klassName: '' },
    { id: 3, label: 'Messaggio', fieldName: 'message', type: 'textarea', placeholder: 'Write something.....', isRequired: true, klassName: '' }
  ]
}
export default function Landing() {
  return (
    <>
      <Header />

      <Body />

      <Footer />
      {/* <a href="#" className="backtotop" style={{ bottom: "17px" }}>
        <FontAwesomeIcon icon={solid('chevron-up')} />
      </a> */}
      <StructuredData />
    </>

  );
}
